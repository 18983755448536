import { Dispatch, SetStateAction } from "react";
import { SetSelectedItemPayload } from "../../../store/selected-item-slice";
import { store } from "../../../store/store";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

export const DEFAULT_PAGE_SIZE = 5;
export const DEFAULT_PAGE = 1;

export function zfTableDefaultOptions(
  handleError?: (error: boolean) => void,
  initialPage: number = 1,
  initialPageSize: number = 5
): any {
  return {
    rowHeight: 30,
    layout: "fitColumns",
    autoColumns: false,
    pagination: true,
    paginationMode: "remote",
    ajaxURL: "http://example.com",
    dataLoaderError: (e: any) => {
      //handleError(true);
      console.log("error");
    },
    paginationSize: DEFAULT_PAGE_SIZE,
    paginationSizeSelector: [3, 5, 10, 20, 50, 100],
    paginationInitialPage: 2, //initialPage, //deprecated
    paginationCounter: function (
      pageSize: number,
      currentRow: number,
      currentPage: number,
      totalRows: number,
      totalPages: number
    ) {
      if (totalRows === null) return;
      const startRow = (currentPage - 1) * pageSize + 1;
      const endRow = Math.min(currentPage * pageSize, totalRows);
      return `Showing rows ${startRow}-${endRow} of ${totalRows} total`;
    },
    filterMode: "remote",
    sortMode: "remote",
    dataLoader: false,
    ajaxResponse: (url: string, params: any, response: any) => {
      //console.log("zf-table ajax response: \n", response);
      const tableData = {
        data: response._embedded?.entities,
        last_page: response.page?.totalPages,
        last_row: response.page?.totalElements,
      };
      return tableData;
    },
    placeholder: "No Data Available",
  };
}

export function tableBuiltHandler(
  event: CustomEvent<any>,
  setApiFetchingState?: Dispatch<SetStateAction<boolean>>,
  cellEditedCallBack?: (cell: any, table: any) => void,
  selectedItemContextName?: string,
  setSelectedItemContext?: ActionCreatorWithPayload<
    SetSelectedItemPayload<any>,
    `${string}/setSelectedItem`
  >,
  rowSelectedCallBack?: Function,
  hideFooter?: boolean,
  apiFetchingState?: () => boolean
) {
  const table = event.detail;

  const selectedItemContext = getSelectedContext(selectedItemContextName);

  let alreadyFetched = false;

  console.log("tableBuiltHandler", apiFetchingState?.(), selectedItemContext);

  if (hideFooter) {
    table.element.querySelector(".tabulator-footer").style.display = "none";
  }

  if (
    selectedItemContext &&
    selectedItemContext.sort &&
    selectedItemContext.sort.column &&
    selectedItemContext.sort.dir
  ) {
    table.setSort(
      selectedItemContext.sort.column,
      selectedItemContext.sort.dir
    );
    console.log("setSort()");
    alreadyFetched = true;
  }

  if (
    selectedItemContext &&
    selectedItemContext.pageSize !== DEFAULT_PAGE_SIZE
  ) {
    table.setPageSize(selectedItemContext.pageSize);
    console.log("setPageSize()");
    alreadyFetched = true;
  }

  if (selectedItemContext && selectedItemContext.page !== DEFAULT_PAGE) {
    table.setPage(selectedItemContext.page);
    console.log("setPage()");
    alreadyFetched = true;
  }

  if (!alreadyFetched && !table.destroyed && table.getData() !== undefined) {
    table.setData();
  }

  if (cellEditedCallBack) {
    table.on("cellEdited", function (cell: any) {
      cellEditedCallBack(cell, table);
    });
  }

  table.on("dataLoading", function (data: any) {});

  table.on("dataLoaded", (data: any) => {
    console.log("dataLoaded");
    setTimeout(() => {
      preSelectRow(table, selectedItemContextName, setSelectedItemContext);
    }, 10);

    setTimeout(() => {
      setApiFetchingState?.(false);
    }, 1500);

    //table.setPlaceholder("No data available");
    // Check if data array is empty
    /*if (data.length === 0) {
      
    }*/
  });

  table.on("renderComplete", (a: any, b: any, c: any) => {
    setApiFetchingState?.(false);
    //console.log("DEBUG - zfTable event 'renderComplete'");
  });

  table.on("renderStarted", function () {
    //console.log("DEBUG - zfTable event 'renderStarted'");
  });

  table.on("pageLoaded", (pageNo: number) => {
    //setApiFetchingState(false);
    const selectedItemContext = getSelectedContext(selectedItemContextName);
    if (!selectedItemContext || !setSelectedItemContext) return;
    const page = table.getPage();
    console.log("Dispatching page number:", page);
    store.dispatch(
      setSelectedItemContext({
        ...selectedItemContext,
        page,
      })
    );
  });

  table.on("pageSizeChanged", (size: number) => {
    const selectedItemContext = getSelectedContext(selectedItemContextName);
    if (!selectedItemContext || !setSelectedItemContext) return;
    const pageSize = table.getPageSize();
    console.log("Dispatching page size:", pageSize);
    store.dispatch(
      setSelectedItemContext({
        ...selectedItemContext,
        pageSize: size,
      })
    );
  });

  table.on("dataSorted", function (sorters: any, rows: any) {
    //sorters - array of the sorters currently applied
    //rows - array of row components in their new order
    const selectedItemContext = getSelectedContext(selectedItemContextName);
    if (!selectedItemContext || !setSelectedItemContext) return;
    //console.log("sorters", sorters);
    const sort =
      sorters.length > 0
        ? {
            column: sorters[0].field,
            dir: sorters[0].dir,
          }
        : {};
    store.dispatch(
      setSelectedItemContext({
        ...selectedItemContext,
        sort,
      })
    );
  });

  table.on("rowClick", (event: PointerEvent, row: any) => {
    console.log("rowClick", event, row);

    if (
      event.target instanceof HTMLElement &&
      event.target?.classList.contains("tabulator-editing")
    ) {
      return;
    }

    const isRadioButton = !!row
      .getCells()[0]
      .getElement()
      .querySelector("zf-radio");

    //const input = (event.target as any)?.querySelector("input");
    //const editing = input != null ? true : false;

    if (!isRadioButton && row.getTable().getSelectedRows().includes(row)) {
      row.getTable().deselectRow(row);
    } else {
      row.getTable().selectRow(row);
    }
  });

  table.on("rowSelected", function (row: any) {
    const selectedItemContext = getSelectedContext(selectedItemContextName);
    if (!selectedItemContext || !setSelectedItemContext) return;
    const selected: any = JSON.parse(JSON.stringify(row._row.data));
    console.log("Selecting page element:", selected);
    store.dispatch(
      setSelectedItemContext({
        ...selectedItemContext,
        selected,
      })
    );
    rowSelectedCallBack?.(row);
  });

  table.on("dataLoadError", function (error: any) {
    setApiFetchingState?.(false);
  });
}

function getSelectedContext(selectedItemContextName?: string) {
  return selectedItemContextName
    ? store.getState()[selectedItemContextName]
    : undefined;
}

async function preSelectRow(
  table: any,
  selectedItemContextName?: string,
  setSelectedItemContext?: ActionCreatorWithPayload<
    SetSelectedItemPayload<any>,
    `${string}/setSelectedItem`
  >
) {
  if (!selectedItemContextName) return;
  const selectedItemContext = getSelectedContext(selectedItemContextName);

  if (!selectedItemContext || !selectedItemContext.selected) return;

  let idx = table
    .getData()
    .findIndex((e: any) => e.id === selectedItemContext?.selected?.id);
  if (idx < 0) {
    if (!setSelectedItemContext) return;
    store.dispatch(
      setSelectedItemContext({
        ...selectedItemContext,
        selected: null,
      })
    );
    return;
  }

  console.log("selecting row at index ", idx);

  const row = table.getRows()[idx];
  if (row) {
    table.selectRow(row);
  }
}
