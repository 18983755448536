interface OptionsList<T, U> {
  entries: U[];
  matchDisplayEntryRowField: keyof T;
  matchEditEntryRowField: keyof T;
  entryKeyField: keyof U;
  entryValueField: keyof U;
}

export function createDoubleLineFormatter<
  T extends object,
  U extends object = any
>(displayField: keyof T, editField: keyof T, optionList?: OptionsList<T, U>) {
  return function (cell: any, formatterParams: any, onRendered: any) {
    var data = cell.getRow().getData();
    let displayValue =
      optionList == null
        ? data[displayField]
        : optionList.entries.filter((e) => {
            return (
              e[optionList.entryKeyField] ===
              data[optionList.matchDisplayEntryRowField]
            );
          })[0][optionList.entryValueField];

    let editValue =
      editField === ""
        ? null
        : optionList == null
        ? data[editField]
        : optionList.entries.filter((e) => {
            return (
              e[optionList.entryKeyField] ===
              data[optionList.matchEditEntryRowField]
            );
          })[0][optionList.entryValueField];

    return `<div style="line-height: 1.4; padding: 3px 0; position: relative">
      <div style="font-size: 12px; margin-bottom: 8px; position: relative">${displayValue}</div>
      <div style="color: #666; font-size: 12px; position: relative">${
        editValue ? editValue : ""
      }</div>
    </div>`;
  };
}

export function createDoubleLineEditor<T extends object>(
  displayField: keyof T,
  editField: keyof T
) {
  return function (cell: any, onRendered: any, success: any, cancel: any) {
    var data = cell.getRow().getData();
    var isEditing = true;

    var wrapper = document.createElement("div");
    wrapper.style.cssText = `
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      line-height: 1.4;
      padding: 3px 0;
    `;
    // background: white;

    var nameDisplay = document.createElement("div");
    nameDisplay.textContent = data[displayField];
    nameDisplay.style.cssText = `
      font-size: 12px;
      margin-bottom: 8px;
      margin-left: 12px;
      position: relative;
      width: 100%;
    `;
    wrapper.appendChild(nameDisplay);

    var input = document.createElement("input");
    input.value = data[editField];
    input.style.cssText = `
      width: 100%;
      box-sizing: border-box;
      padding: 1px 4px;
      font-size: 12px;
      height: 15px;
      position: relative;
    `;
    wrapper.appendChild(input);

    function handleUpdate() {
      if (isEditing) {
        isEditing = false;
        success(input.value);
      }
    }

    onRendered(function () {
      input.focus();
      input.select();
    });

    input.addEventListener("blur", handleUpdate);
    input.addEventListener("keydown", function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        handleUpdate();
      }
      if (e.keyCode === 27) {
        isEditing = false;
        cancel();
      }
    });

    return wrapper;
  };
}

interface SelectConfig<U> {
  options: U[];
  optionValueField: keyof U;
  optionDisplayField: keyof U;
  useOptionsToDisplayValues?: boolean;
}

export function createDoubleLineSelectEditor<
  T extends object,
  U extends object
>(
  rowDisplayField: keyof T,
  rowEditField: keyof T,
  selectConfig: SelectConfig<U>
) {
  return function (cell: any, onRendered: any, success: any, cancel: any) {
    var data = cell.getRow().getData();
    var isEditing = true;

    var wrapper = document.createElement("div");
    wrapper.style.cssText = `
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      line-height: 1.4;
      padding: 3px 0;
    `;

    var nameDisplay = document.createElement("div");
    nameDisplay.textContent = selectConfig.useOptionsToDisplayValues
      ? selectConfig.options.filter(
          (e) => e[selectConfig.optionValueField] === data[rowDisplayField]
        )[0][selectConfig.optionDisplayField]
      : data[rowDisplayField];

    nameDisplay.style.cssText = `
      font-size: 12px;
      margin-bottom: 8px;
      margin-left: 12px;
      position: relative;
      width: 100%;
    `;
    wrapper.appendChild(nameDisplay);

    var select = document.createElement("select");
    select.style.cssText = `
      width: 100%;
      box-sizing: border-box;
      padding: 1px 4px;
      font-size: 12px;
      height: 22px;
      position: relative;
    `;

    // Populate select options from the generic options array
    selectConfig.options.forEach((option) => {
      var optElement = document.createElement("option");
      optElement.value = String(option[selectConfig.optionValueField]);
      optElement.text = String(option[selectConfig.optionDisplayField]);
      if (option[selectConfig.optionValueField] === data[rowEditField]) {
        optElement.selected = true;
      }
      select.appendChild(optElement);
    });

    wrapper.appendChild(select);

    function handleUpdate() {
      if (isEditing) {
        isEditing = false;
        const newOptionValue = Number(select.value);
        success(newOptionValue);
      }
    }

    onRendered(function () {
      select.focus();
    });

    select.addEventListener("blur", handleUpdate);
    select.addEventListener("keydown", function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        handleUpdate();
      }
      if (e.keyCode === 27) {
        isEditing = false;
        cancel();
      }
    });

    select.addEventListener("change", handleUpdate);

    return wrapper;
  };
}
