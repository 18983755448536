import { faker } from "@faker-js/faker";
import {
  EmailTemplateDto,
  EntityModelAccountBalanceCobDto,
  EntityModelAccountBalanceDto,
  EntityModelAccountBalanceLimitBreachDto,
  EntityModelAccountBalanceMissingCobDto,
  EntityModelAccountLimitDto,
  EntityModelEmailPreviewDto,
  EntityModelNostroCategoryDto,
  EntityModelNostroCommentDto,
  EntityModelNostroOverviewDto,
  EntityModelNostroStagingUpdateDto,
  EntityModelNostroVsAccountBalanceDto,
  EntityModelOtherNostroViewDto,
  EntityModelRelationshipManagerDto,
  EntityModelRMStagingUpdateDto,
  EntityModelStatusDto,
  NostroCommentDto,
  NostroDetailDto,
  NostroRiskDto,
  PagedModelEntityModelAccountDto,
  PagedModelEntityModelAccountLimitDto,
  PagedModelEntityModelComponentPropertiesDto,
  PagedModelEntityModelEmailPreviewDto,
  PagedModelEntityModelNostroLimitDto,
  PagedModelEntityModelNostroStagingUpdateDto,
  PagedModelEntityModelRMStagingUpdateDto,
} from "../openapi";

export type ComponentProperty = {
  componentId: string;
  label: string;
  column?: string;
  order?: number;
  openDialog?:
    | "import-account-balance"
    | "export-nos-vs-acc-bal"
    | "export-nos-lim";
};

const viewAccProps: ComponentProperty = {
  componentId: "acc-view",
  label: "View Nostro",
  column: "Main",
  order: 2,
};

const otherViewsProps: ComponentProperty = {
  componentId: "other-views",
  label: "Other Views",
  column: "Main",
  order: 3,
};

const templatesProps: ComponentProperty = {
  componentId: "templates",
  label: "Templates",
  column: "Balance Recon",
  order: 11,
};

const accountCatProps: ComponentProperty = {
  componentId: "acc-cat-view",
  label: "View Nostroount Category",
  column: "Masterdata",
  order: 21,
};

const viewRelManagerProps: ComponentProperty = {
  componentId: "view-relationship-manager",
  label: "View Relationship Manager",
  column: "Masterdata",
  order: 22,
};

const importAccBalProps: ComponentProperty = {
  componentId: "import-account-balance",
  label: "Import Account Balance",
  column: "Main",
  order: 1,
  openDialog: "import-account-balance",
};

const exportNosVsAccBal: ComponentProperty = {
  componentId: "export-nos-vs-acc-bal",
  label: "Export NostroVsAccBal",
  column: "Admin",
  order: 42,
  openDialog: "export-nos-vs-acc-bal",
};

const exportNosLim: ComponentProperty = {
  componentId: "export-nos-lim",
  label: "Export Nostro Limits XLS",
  column: "Admin",
  order: 34,
  openDialog: "export-nos-lim",
};

//"properties": "[{\"key\":\"label\",\"value\":\"Import ACC_BAL\"},{\"key\":\"column\",\"value\":\"Main\"},{\"key\":\"onclick\",\"value\":\"openDialog\"}]"

export const componentProps = [
  { componentId: "acc-view", properties: JSON.stringify(viewAccProps) },
  { componentId: "templates", properties: JSON.stringify(templatesProps) },
  { componentId: "acc-cat-view", properties: JSON.stringify(accountCatProps) },
  { componentId: "other-views", properties: JSON.stringify(otherViewsProps) },
  {
    componentId: "export-nos-vs-acc-bal",
    properties: JSON.stringify(exportNosVsAccBal),
  },
  {
    componentId: "view-relationship-manager",
    properties: JSON.stringify(viewRelManagerProps),
  },
  {
    componentId: "import-account-balance",
    properties: JSON.stringify(importAccBalProps),
  },
  {
    componentId: "export-nos-lim",
    properties: JSON.stringify(exportNosLim),
  },
];

export function getComponentProperties200Response2() {
  const resp: PagedModelEntityModelComponentPropertiesDto = {
    _embedded: {
      entities: componentProps,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };

  return resp;
}

export function getComponentProperties200Response3() {
  const resp: PagedModelEntityModelComponentPropertiesDto = {
    _embedded: {
      entities: componentProps,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };

  return resp;
}

const accountCatEntities: EntityModelNostroCategoryDto[] = [
  {
    createUser: "udo.be",
    createTs: "03.06.2024 16:25:43",
    updateUser: "udo.be",
    updateTs: "03.06.2024 16:49:28",
    statusId: 2,
    statusCode: "del",
    statusText: "delete",
    id: 2,
    code: "AAA",
    text: null,
    comment: "Test comment",
  },
  {
    createUser: "udo.be",
    createTs: "03.06.2024 16:28:59",
    updateUser: "udo.be",
    updateTs: "03.06.2024 16:28:59",
    statusId: 2,
    statusCode: "del",
    statusText: "delete",
    id: 3,
    code: "A2A",
    text: "sa2",
    comment: "Test comment",
  },
  {
    createUser: "udo.be",
    createTs: "03.06.2024 16:35:52",
    updateUser: "udo.be",
    updateTs: "03.06.2024 16:35:52",
    statusId: 2,
    statusCode: "del",
    statusText: "delete",
    id: 5,
    code: "A3A",
    text: "sa2",
    comment: "",
  },
  {
    createUser: "udo.be",
    createTs: "03.06.2024 12:21:04",
    updateUser: "udo.be",
    updateTs: "03.06.2024 15:59:14",
    statusId: 1,
    statusCode: "chk",
    statusText: "check",
    id: 21,
    code: "n.a.",
    text: "ciao",
    comment: "bla2",
  },
];

export function getAllNostroCategories200Response2() {
  return {
    _embedded: {
      entities: accountCatEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

export function getGetAllNostroCategories200Response3() {
  return {
    data: accountCatEntities,
    last_page: 10,
  };
}

export function getNostroCategory200Response2() {
  return accountCatEntities[1];
}

const nostroEntities: EntityModelNostroOverviewDto[] = [
  {
    id: 1,
    number: "96500074",
    name: null,
    code: "A3A",
    riskComplianceCode: "na",
    riskFraudCode: "na",
    status: "check",
    upn: "u23840",
    commentCount: 4,
    kstaChfBr: 2,
    kstaCcyBr: 0,
    busaldoChfBr: 2,
    busaldoCcyBr: 0,
    hasLimits: 0,
  },
  {
    id: 2,
    number: "96500075",
    name: null,
    code: "A3A",
    riskComplianceCode: "na",
    riskFraudCode: "na",
    status: "check",
    upn: "u23840",
    commentCount: 0,
    kstaChfBr: 1,
    kstaCcyBr: 0,
    busaldoChfBr: 1,
    busaldoCcyBr: 0,
    hasLimits: 0,
  },
];

export function getAllNostro200Response() {
  return {
    _embedded: {
      entities: nostroEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const AccountBalanceDtoEntities: EntityModelAccountBalanceDto[] = [
  {
    id: 1,
    number: "2661.001.02",
    currency: "CHF",
    cobDate: "24.07.2024",
    description: "CHF",
    kstaValueChf: "-9394000.06",
    kstaValueChfBreach: true,
    kstaValueCcy: "-9394000.06",
    kstaValueCcyBreach: false,
    busaldoDate: "19.03.2024",
    busaldoValueChf: "9394.06",
    busaldoValueChfBreach: true,
    busaldoValueCcy: "9394.06",
    busaldoValueCcyBreach: false,
    hasLimits: false,
  },
  {
    id: 4,
    number: "2661.001.01",
    currency: "CHF",
    cobDate: "10.06.2024",
    description: "CHF",
    kstaValueChf: "9394.06",
    kstaValueChfBreach: true,
    kstaValueCcy: "9394.06",
    kstaValueCcyBreach: false,
    busaldoDate: "19.03.2024",
    busaldoValueChf: "9394.06",
    busaldoValueChfBreach: true,
    busaldoValueCcy: "9394.06",
    busaldoValueCcyBreach: false,
    hasLimits: false,
  },
];

export function getAllAccountBalance200Response() {
  return {
    _embedded: {
      entities: AccountBalanceDtoEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const nostroDetail: NostroDetailDto = {
  id: 1,
  number: "96500074",
  name: "Test Name",
  addrL1: "ANON VRCGDVRANN",
  addrL2: "ANON VRCGDVRANNTRLOKAGKRYREB",
  addrL3: "ANON CDYAHCWRTNHUZIVLUPLSABAG",
  addrL4: "ANON DDBTTULHNQZHLOOFOIUOYPFKP",
  description: "test20203 test",
  nostroCategoryId: 5,
  riskFraudId: 1,
  riskComplianceId: 1,
  rmId: 1,
  largeTurnover: true,
  plRelevant: true,
  watchdog: false,
  depotRelevant: false,
  statusId: 1,
  valChfLimit: 120,
  rmNumber: "AEBT",
  upn: "u23840",
  rmName: "OH SMZBONDC ISJID",
  phone: "YdvDhtAsLghPXAgtbprXPZkhnfLTBSX",
  orgUnit: "TXLC",
  emailSentAt: null,
  emailSentBy: null,
};

export function getNostroDetail200Response() {
  return nostroDetail;
}

const relationshipManagerEntities: Array<EntityModelRelationshipManagerDto> = [
  {
    createUser: "udo.be",
    createTs: "11.06.2024 14:49:21",
    updateUser: "udo.be",
    updateTs: "11.06.2024 14:49:21",
    statusId: 3,
    statusCode: "new",
    statusText: "new",
    id: 139,
    number: "BDYX",
    upn: "",
    name: null,
    phone: null,
    address: null,
    orgUnit: null,
    emailSentAt: null,
    emailSentBy: "null",
  },
  {
    createUser: "udo.be",
    createTs: "11.06.2024 14:49:21",
    updateUser: "udo.be",
    updateTs: "11.06.2024 14:49:21",
    statusId: 3,
    statusCode: "new",
    statusText: "new",
    id: 140,
    number: "BERM",
    upn: "",
    name: null,
    phone: null,
    address: null,
    orgUnit: null,
    emailSentAt: null,
    emailSentBy: "null",
  },
  {
    createUser: "ADMIN",
    createTs: "05.06.2024 16:39:16",
    updateUser: "udo.be",
    updateTs: "05.06.2024 16:42:05",
    statusId: 2,
    statusCode: "del",
    statusText: "delete",
    id: 1,
    number: "0007",
    upn: "000001",
    name: null,
    phone: null,
    address: null,
    orgUnit: null,
    emailSentAt: null,
    emailSentBy: "null",
  },
  {
    createUser: "udo.be",
    createTs: "11.06.2024 14:49:21",
    updateUser: "udo.be",
    updateTs: "11.06.2024 14:49:21",
    statusId: 3,
    statusCode: "new",
    statusText: "new",
    id: 138,
    number: "4786",
    upn: "",
    name: null,
    phone: null,
    address: null,
    orgUnit: null,
    emailSentAt: null,
    emailSentBy: "null",
  },
];

export function getAllRelationshipManagers200Response2() {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = relationshipManagerEntities;
  response.page.totalElements = relationshipManagerEntities.length;
  return response;
}

const statusEntities: EntityModelStatusDto[] = [
  {
    statusId: 1,
    statusCode: "new",
    statusText: "new",
  },
  {
    statusId: 2,
    statusCode: "del",
    statusText: "delete",
  },
];

export function getAllStatuses200Response() {
  return {
    _embedded: {
      entities: statusEntities,
    },
    // page: {
    //   size: 2,
    //   totalElements: 2,
    //   totalPages: 1,
    //   number: 0,
    // },
  };
}

export function getGetLastRelationshipManagersImport200Response2() {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = [
    {
      createUser: "occ_t1",
      createTs: "30.10.2024 15:55:11",
      updateUser: "occ_t1",
      updateTs: "30.10.2024 15:55:11",
    },
  ];
  response.page = 1;
  return response;
}

const accountBalanceCobDtoEntities: EntityModelAccountBalanceCobDto[] = [
  {
    id: 2,
    cobDate: "10.06.2024",
    count: 1,
  },
  {
    id: 3,
    cobDate: "05.07.2024",
    count: 1,
  },
  {
    id: 1,
    cobDate: "24.07.2024",
    count: 1,
  },
  {
    id: 4,
    cobDate: "24.07.2024",
    count: 1,
  },
  {
    id: 5,
    cobDate: "24.07.2024",
    count: 1,
  },
];

export function getAllAccountBalanceCob200Response() {
  return {
    _embedded: {
      entities: accountBalanceCobDtoEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const accountBalanceLimitBreachDtoEntities: EntityModelAccountBalanceLimitBreachDto[] =
  [
    {
      id: 1,
      number: "96500074",
      name: null,
      riskFraud: "not applicable",
      status: "check",
      count: 2,
      kstaChf: 2,
      kstaCcy: 0,
      busaldoValueChf: 2,
      busaldoValueCcy: 2,
    },
    {
      id: 2,
      number: "96500075",
      name: null,
      riskFraud: "not applicable",
      status: "check",
      count: 1,
      kstaChf: 1,
      kstaCcy: 0,
      busaldoValueChf: 1,
      busaldoValueCcy: 1,
    },
  ];

export function getAllAccountBalanceLimitBreach200Response() {
  return {
    _embedded: {
      entities: accountBalanceLimitBreachDtoEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const accountBalanceMissingCobDtoEntities: EntityModelAccountBalanceMissingCobDto[] =
  [
    {
      id: 2,
      cobDate: "10.06.2024",
      count: 0,
      weekday: "3",
    },
    {
      id: 32,
      cobDate: "11.06.2024",
      count: 2,
      weekday: "3",
    },
  ];

export function getAllAccountBalanceMissingCob200Response() {
  return {
    _embedded: {
      entities: accountBalanceMissingCobDtoEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const nostroVsAccountBalanceEntities: EntityModelNostroVsAccountBalanceDto[] = [
  {
    id: 1,
    number: "96500074",
    name: null,
    riskFraud: "not applicable",
    status: "check",
    count: 4,
    minCobDate: "10.06.2024",
    maxCobDate: "24.07.2024",
  },
  {
    id: 2,
    number: "96500075",
    name: null,
    riskFraud: "not applicable",
    status: "check",
    count: 1,
    minCobDate: "24.07.2024",
    maxCobDate: "24.07.2024",
  },
];

export function getAllNostroVsAccountBalance200Response() {
  return {
    _embedded: {
      entities: nostroVsAccountBalanceEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const otherNostroViewDtoEntities: Array<EntityModelOtherNostroViewDto> = [
  {
    id: 1,
    title: "Cob Date Missing Account Balance",
    count: 35,
    route: "/other-views/cob-dt-missing",
  },
  {
    id: 2,
    title: "Nostro Vs Account Balance",
    count: 2,
    route: "/other-views/nos-vs-acc-bal",
  },
  {
    id: 3,
    title: "Account Balance Limit Breach",
    count: 2,
    route: "/other-views/acc-bal-lim-br",
  },
  {
    id: 4,
    title: "Cob Date Vs Account Balance",
    count: 5,
    route: "/other-views/cob-dt-vs-acc-bal",
  },
];

const springResponseWrapper = {
  _embedded: {
    entities: otherNostroViewDtoEntities,
  },
  _links: [Array.from(Array(5)).keys()]
    .map((_) => ({
      [faker.lorem.word()]: {
        href: faker.lorem.slug(1),
        hreflang: faker.lorem.slug(1),
        title: faker.lorem.slug(1),
        type: faker.lorem.slug(1),
        deprecation: faker.lorem.slug(1),
        profile: faker.lorem.slug(1),
        name: faker.person.fullName(),
        templated: faker.datatype.boolean(),
      },
    }))
    .reduce((acc, next) => Object.assign(acc, next), {}),
  page: {
    size: 5, //faker.number.int({ min: undefined, max: undefined }),
    totalElements: 10, //faker.number.int({ min: undefined, max: undefined }),
    totalPages: 1, //faker.number.int({ min: undefined, max: undefined }),
    number: 1, //faker.number.int({ min: undefined, max: undefined }),
  },
};

export function getOtherNostroViews200Response2() {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = otherNostroViewDtoEntities;
  return response;
}

const nostroRiskDtoEntities: NostroRiskDto[] = [
  {
    statusId: 1,
    statusCode: "chk",
    statusText: "check",
    id: 1,
    code: "na",
    text: "not applicable",
  },
];

export function getAllNostroRiskFraud200Response2() {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = nostroRiskDtoEntities;
  return response;
}

export function getAllNostroRiskCompliance200Response2() {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = nostroRiskDtoEntities;
  return response;
}

const nostroComments: EntityModelNostroCommentDto[] = [
  {
    createUser: "udo.be",
    createTs: "16.08.2024",
    updateUser: "occ_t1",
    updateTs: "26.09.2024",
    id: 22,
    nostroCommentStatusText: "Status Two",
    showOnReport: true,
    comment: "test1",
    link: "test1",
    commentLineCount: 0,
    nostroCommentStatusId: 2,
  },
  {
    createUser: "udo.be",
    createTs: "16.08.2024",
    updateUser: "udo.be",
    updateTs: "16.08.2024",
    id: 21,
    nostroCommentStatusText: "Pending",
    showOnReport: false,
    comment: "comment 2 update",
    link: "null",
    commentLineCount: 0,
    nostroCommentStatusId: 1,
  },
  {
    createUser: "udo.be",
    createTs: "16.08.2024",
    updateUser: "udo.be",
    updateTs: "16.08.2024",
    id: 19,
    nostroCommentStatusText: "Pending",
    showOnReport: false,
    comment: "new comment update",
    link: "null",
    commentLineCount: 0,
    nostroCommentStatusId: 1,
  },
  {
    createUser: "udo.be",
    createTs: "16.08.2024",
    updateUser: "udo.be",
    updateTs: "16.08.2024",
    id: 18,
    nostroCommentStatusText: "Status Two",
    showOnReport: true,
    comment: "new comment",
    link: null,
    commentLineCount: 0,
    nostroCommentStatusId: 2,
  },
];

export function getAllCommentsForNostro200Response2() {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = nostroComments;
  return response;
}

const nostroCommentStatuses = [
  {
    createUser: null,
    createTs: null,
    updateUser: null,
    updateTs: null,
    statusId: 1,
    statusText: "check",
    id: 1,
    code: "1",
    text: "Pending",
    simpleStatus: null,
  },
  {
    createUser: null,
    createTs: null,
    updateUser: null,
    updateTs: null,
    statusId: 1,
    statusText: "check",
    id: 2,
    code: "1",
    text: "Status Two",
    simpleStatus: null,
  },
];

export function getAllNostroCommentStatusResponse2() {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = nostroCommentStatuses;
  return response;
}

const nostroCommentDetail: NostroCommentDto = {
  createUser: "udo.be",
  createTs: "16.08.2024",
  updateUser: "occ_t1",
  updateTs: "26.09.2024",
  id: 22,
  nostroCommentStatusText: "Status Two",
  showOnReport: true,
  comment: "test1",
  link: "test1",
  commentLineCount: 0,
  nostroCommentStatusId: 2,
};

export function getNostroCommentDetailResponse2(): NostroCommentDto {
  return nostroCommentDetail;
}

const nostroLimits: Array<EntityModelAccountLimitDto> = [
  {
    createUser: "udo.be",
    createTs: "29.10.2024",
    updateUser: "udo.be",
    updateTs: "30.10.2024",
    statusId: 6,
    statusCode: "0",
    statusText: "new",
    id: 97,
    accountNostroNumber: "96500031",
    accountNumber: "2110.020.01",
    accountCurrencyLabel: "ZAR",
    kstaValueCcyLimitLow: "10",
    kstaValueCcyLimitHigh: "12",
    busaldoValueCcyLimitLow: "12",
    busaldoValueCcyLimitHigh: "12",
    remark: null,
    validFrom: null,
    validTo: null,
  },
  {
    createUser: "udo.be",
    createTs: "29.10.2024",
    updateUser: "udo.be",
    updateTs: "29.10.2024",
    statusId: 1,
    statusCode: "0",
    statusText: "check",
    id: 98,
    accountNostroNumber: "96500031",
    accountNumber: "2110.020.01",
    accountCurrencyLabel: "ZAR",
    kstaValueCcyLimitLow: "10",
    kstaValueCcyLimitHigh: "122",
    busaldoValueCcyLimitLow: "12",
    busaldoValueCcyLimitHigh: "12",
    remark: null,
    validFrom: null,
    validTo: null,
  },
];

export function getAllAccountLimitForNostroResponse2(): PagedModelEntityModelAccountLimitDto {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = nostroLimits;
  return response;
}

const nostroAccounts = [
  {
    createUser: "occ_t1",
    createTs: "28.10.2024 10:18:16",
    updateUser: "occ_t1",
    updateTs: "28.10.2024 10:18:16",
    statusId: 6,
    statusCode: null,
    statusText: "new",
    id: 43,
    number: "2110.001.01",
    nostroId: 3,
    nostroNumber: "96500031",
    hasLimits: false,
  },
  {
    createUser: "occ_t1",
    createTs: "28.10.2024 10:18:16",
    updateUser: "occ_t1",
    updateTs: "28.10.2024 10:18:16",
    statusId: 6,
    statusCode: null,
    statusText: "new",
    id: 38,
    number: "2110.001.03",
    nostroId: 3,
    nostroNumber: "96500031",
    hasLimits: false,
  },
  {
    createUser: "occ_t1",
    createTs: "28.10.2024 10:18:16",
    updateUser: "occ_t1",
    updateTs: "28.10.2024 10:18:16",
    statusId: 6,
    statusCode: null,
    statusText: "new",
    id: 7,
    number: "2110.020.01",
    nostroId: 3,
    nostroNumber: "96500031",
    hasLimits: true,
  },
  {
    createUser: "occ_t1",
    createTs: "28.10.2024 10:18:16",
    updateUser: "occ_t1",
    updateTs: "28.10.2024 10:18:16",
    statusId: 6,
    statusCode: null,
    statusText: "new",
    id: 39,
    number: "2110.088.01",
    nostroId: 3,
    nostroNumber: "96500031",
    hasLimits: false,
  },
  {
    createUser: "occ_t1",
    createTs: "28.10.2024 10:18:16",
    updateUser: "occ_t1",
    updateTs: "28.10.2024 10:18:16",
    statusId: 6,
    statusCode: null,
    statusText: "new",
    id: 16,
    number: "2110.088.03",
    nostroId: 3,
    nostroNumber: "96500031",
    hasLimits: false,
  },
  {
    createUser: "occ_t1",
    createTs: "28.10.2024 10:18:16",
    updateUser: "occ_t1",
    updateTs: "28.10.2024 10:18:16",
    statusId: 6,
    statusCode: null,
    statusText: "new",
    id: 22,
    number: "2110.184.01",
    nostroId: 3,
    nostroNumber: "96500031",
    hasLimits: false,
  },
  {
    createUser: "occ_t1",
    createTs: "28.10.2024 10:18:16",
    updateUser: "occ_t1",
    updateTs: "28.10.2024 10:18:16",
    statusId: 6,
    statusCode: null,
    statusText: "new",
    id: 19,
    number: "2110.184.03",
    nostroId: 3,
    nostroNumber: "96500031",
    hasLimits: false,
  },
  {
    createUser: "occ_t1",
    createTs: "28.10.2024 10:18:16",
    updateUser: "occ_t1",
    updateTs: "28.10.2024 10:18:16",
    statusId: 6,
    statusCode: null,
    statusText: "new",
    id: 14,
    number: "2110.206.01",
    nostroId: 3,
    nostroNumber: "96500031",
    hasLimits: false,
  },
  {
    createUser: "occ_t1",
    createTs: "28.10.2024 10:18:16",
    updateUser: "occ_t1",
    updateTs: "28.10.2024 10:18:16",
    statusId: 6,
    statusCode: null,
    statusText: "new",
    id: 27,
    number: "2110.248.01",
    nostroId: 3,
    nostroNumber: "96500031",
    hasLimits: false,
  },
  {
    createUser: "occ_t1",
    createTs: "28.10.2024 10:18:16",
    updateUser: "occ_t1",
    updateTs: "28.10.2024 10:18:16",
    statusId: 6,
    statusCode: null,
    statusText: "new",
    id: 28,
    number: "2110.248.03",
    nostroId: 3,
    nostroNumber: "96500031",
    hasLimits: false,
  },
];

export function getAllNostroAccountsResponse2(): PagedModelEntityModelAccountDto {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = nostroAccounts;
  response.page.size = 10;
  response.page.number = 1;
  response.page.totalPages = 1;
  response.page.totalElements = nostroAccounts.length;
  return response;
}

const limitsForNostro = [
  [
    {
      createUser: "ADMIN",
      createTs: "11.11.2024",
      updateUser: "ADMIN",
      updateTs: "11.11.2024",
      id: 555,
      valChf: "100000",
      validFrom: "01.01.2000",
      validTo: null,
      remark: null,
    },
  ],
];

export function getAllNostroLimitForNostro2(): PagedModelEntityModelNostroLimitDto {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = limitsForNostro;
  return response;
}

const emails: Array<EntityModelEmailPreviewDto> = [
  {
    id: 1,
    upn: "u23840",
    name: "OH SMZBONDC ISJID",
    nostroCount: 1,
    accountBalanceCount: 1,
  },
  {
    id: 125,
    upn: "5",
    name: "5",
    nostroCount: 1,
    accountBalanceCount: 4,
  },
];

export function getEmailPreview200Response2(): PagedModelEntityModelEmailPreviewDto {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = emails;
  response.page.totalElements = emails.length;
  return response;
}

const nosToUpdate: Array<EntityModelNostroStagingUpdateDto> = [
  {
    id: 1,
    nostroStagingId: 101,
    number: "N12345",
    name: "Entity One",
    stagedName: "Updated Entity One",
    addrL1: "123 Main St",
    addrL2: "Suite 100",
    addrL4: "New York, NY",
    stagedAddrL1: "456 Elm St",
    stagedAddrL2: "Apt 2",
    stagedAddrL4: "Los Angeles, CA",
    rmId: 5001,
    rmNumber: "RM5001",
    stagedRmId: 5002,
    stagedRmNumber: "RM5002",
    statusId: 1,
    _links: undefined, // Add link structure here if needed
  },
  {
    id: 2,
    nostroStagingId: 102,
    number: "N67890",
    name: "Entity Two",
    stagedName: "Updated Entity Two",
    addrL1: "789 Oak St",
    addrL2: "Floor 3",
    addrL4: "Chicago, IL",
    stagedAddrL1: "101 Pine St",
    stagedAddrL2: "Building B",
    stagedAddrL4: "Houston, TX",
    rmId: 6001,
    rmNumber: "RM6001",
    stagedRmId: 6002,
    stagedRmNumber: "RM6002",
    statusId: 2,
    _links: undefined, // Add link structure here if needed
  },
];

export function getAllNostroToUpdate200Response2(): PagedModelEntityModelNostroStagingUpdateDto {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = nosToUpdate;
  response.page.totalElements = nosToUpdate.length;
  return response;
}

export function getEmailTemplateWithoutBalance200Respose2(): EmailTemplateDto {
  return {
    createUser: "ADMIN",
    createTs: "05.08.2024 14:53:46",
    updateUser: "occ_t1",
    updateTs: "30.10.2024 15:41:55",
    id: "WITHOUT_BALANCE",
    text: '<p>try <strong>bold </strong><em class="ql-size-large">ciao <u>prova</u></em></p><p><br></p><p><span class="ql-size-large">Buongiorno cari signori, </span></p><p><span class="ql-size-large">la presente per testare l\'invio delle email </span></p><ul><li><span class="ql-size-large">user1</span></li><li><span class="ql-size-large">user 2</span></li></ul><p><span class="ql-size-large">﻿</span></p><p class="ql-indent-2">test TEST</p><p><br></p><p><span class="ql-size-large">Saluti</span></p><p><span class="ql-size-large">Salvatore</span></p>',
  };
}

const rmToUpdate: Array<EntityModelRMStagingUpdateDto> = [
  {
    id: 234,
    rmStagingId: 234,
    number: "BCTY",
    upn: "u63747",
    stagedUpn: "u63747",
    name: "JVOYAM UUCUNV",
    stagedName: "JVOYAM UUCUNV",
    phone: "+60     60    6205335",
    stagedPhone: "+60     60    6205335",
    orgUnit: "JWZA",
    stagedOrgUnit: "JWZA",
    address: "U503 / 35.875",
    stagedAddress: "U503 / 35.876",
    statusId: 4,
  },
  {
    id: 235,
    rmStagingId: 235,
    number: "BDCK",
    upn: "u06727",
    stagedUpn: "u06727",
    name: "ODLJLDSDUL NBHMZCAYQ",
    stagedName: "ODLJLDSDUL NBHMZCAYQ",
    phone: "+93     41    7033802",
    stagedPhone: "+93     41    7033802",
    orgUnit: "LPXF",
    stagedOrgUnit: "LPXF",
    address: "GI98 / 14.910",
    stagedAddress: "GI98 / 14.910",
    statusId: 7,
  },
];

export function getAllRMToUpdate200Response2(): PagedModelEntityModelRMStagingUpdateDto {
  let response = JSON.parse(JSON.stringify(springResponseWrapper));
  response._embedded.entities = rmToUpdate;
  response.page.totalElements = rmToUpdate.length;
  return response;
}
