import { useCallback, useEffect, useRef, useState } from "react";
import {
  ZfBanner,
  ZfIconButton,
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import { useNavigate } from "react-router-dom";
import { CellComponent } from "@ccx/zafire/dist/types/zf-table-type";
import {
  EntityModelNostroStagingUpdateDto,
  EntityModelRelationshipManagerDto,
  EntityModelStatusDto,
  NostroControllerService,
  NostroUpdateDto,
  RelationshipManagerControllerService,
} from "../../../../openapi";
import authorizedCall from "../../../../utils/authorizedCallUtils";
import { useAppSelector } from "../../../../store/store";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../../../../components/common/ZfTable/zftable-configuration";
import {
  actionFormatter,
  customChecboxFormatter,
} from "../../../../components/common/ZfTable/zftable-cell-editors";
import {
  createDoubleLineEditor,
  createDoubleLineFormatter,
  createDoubleLineSelectEditor,
} from "../../../../components/common/ZfTable/zftable-twofields-column";

interface EntityModelNostroStagingUpdateDtoExt
  extends EntityModelNostroStagingUpdateDto {
  newStatusId: number;
}

const NostroToUpdate = () => {
  const navigate = useNavigate();

  const dataTable = useRef<HTMLZfTableElement>(null);
  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);
  const [rms, setRms] = useState<EntityModelRelationshipManagerDto[]>();

  const statuses = useAppSelector((state) => state.comboBoxSlice.statuses);

  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [selected, setSelected] = useState<NostroUpdateDto[]>([]);

  useEffect(() => {
    //setIsFetching(true);

    authorizedCall(
      RelationshipManagerControllerService.getAllRelationshipManagers,
      {}
    )
      .then((res) => {
        console.log("rms", res);
        setRms(res);
      })
      .finally(() => {
        //setIsFetching(false);
      });
  }, []);
  /**
   *
   * @param url the url of the request
   * @param config the ajaxConfig object
   * @param params the ajaxParams object
   * @returns
   */
  const dataTableApiCall = useCallback(
    async (url: any, config: any, params: any) => {
      setIsFetching(true);
      let sortArray: Array<string> =
        params.sort.length > 0
          ? [`${params.sort[0].field},${params.sort[0].dir}`]
          : [];
      // map field to non staging
      sortArray = sortArray.map((e) => {
        if (e.startsWith("stagedName")) e = e.replace("stagedName", "name");
        if (e.startsWith("stagedAddrL1"))
          e = e.replace("stagedAddrL1", "addrL1");
        if (e.startsWith("stagedAddrL2"))
          e = e.replace("stagedAddrL2", "addrL2");
        if (e.startsWith("stagedAddrL4"))
          e = e.replace("stagedAddrL4", "addrL4");
        if (e.startsWith("stagedRmNumber"))
          e = e.replace("stagedRmNumber", "rmNumber");
        if (e.startsWith("newStatusId"))
          e = e.replace("newStatusId", "statusId");
        return e;
      });

      let apiParams: Parameters<
        typeof NostroControllerService.getAllNostroToUpdate
      >[0] = {
        page: params.page,
        size: params.size,
        sort: sortArray,
      };

      if (searchFilter.current?.value) {
        apiParams = { ...apiParams, search: searchFilter.current?.value };
      }

      try {
        try {
          const resp = await authorizedCall(
            NostroControllerService.getAllNostroToUpdate,
            apiParams,
            true
          );
          if (resp._embedded?.entities) {
            resp._embedded.entities = resp._embedded?.entities.map(
              (elem: EntityModelNostroStagingUpdateDto): NostroUpdateDto => {
                return {
                  ...elem,
                  relationshipManagerUpdate:
                    elem.rmNumber !== elem.stagedRmNumber,
                  nameUpdate: elem.name !== elem.stagedName,
                  addressL1Update: elem.addrL1 !== elem.stagedAddrL1,
                  addressL2Update: elem.addrL2 !== elem.stagedAddrL2,
                  addressL4Update: elem.addrL4 !== elem.stagedAddrL4,
                  newStatusId: elem.statusId,
                };
              }
            );
          }
          return resp;
        } catch (error) {
          return error;
        }
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  /**
   *
   * First configuration after renderization
   */
  useEffect(() => {
    if (rms == null) return;

    let defaultOptions = zfTableDefaultOptions(setError);
    defaultOptions.rowHeight = 50;

    const TABLE_COLUMNS = [
      {
        formatter: "zfCheckboxRowSelection",
        titleFormatter: "zfCheckboxTitleRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "Number", field: "number", maxWidth: 100 },
      //{ title: "Name", field: "name" },
      //{ title: "Staged Name", field: "stagedName", editor: "input" },

      // Name
      {
        title: "Name &<br />Staged Name",
        field: "stagedName",
        formatter: createDoubleLineFormatter<EntityModelNostroStagingUpdateDto>(
          "name",
          "stagedName"
        ),
        editor: createDoubleLineEditor<EntityModelNostroStagingUpdateDto>(
          "name",
          "stagedName"
        ),
      },
      {
        titleFormatter: actionFormatter,
        field: "nameUpdate",
        formatter: customChecboxFormatter,
        headerSort: false,
        width: 50,
      },

      // Address L1
      {
        title: "Address L1 &<br/> Staged Address L1",
        field: "stagedAddrL1",
        formatter: createDoubleLineFormatter<EntityModelNostroStagingUpdateDto>(
          "addrL1",
          "stagedAddrL1"
        ),
        editor: createDoubleLineEditor<EntityModelNostroStagingUpdateDto>(
          "addrL1",
          "stagedAddrL1"
        ),
      },
      {
        titleFormatter: actionFormatter,
        field: "addressL1Update",
        formatter: customChecboxFormatter,
        headerSort: false,
        width: 50,
      },

      // Address L2
      {
        title: "Address L2 &<br/> Staged Address L2",
        field: "stagedAddrL2",
        formatter: createDoubleLineFormatter<EntityModelNostroStagingUpdateDto>(
          "addrL2",
          "stagedAddrL2"
        ),
        editor: createDoubleLineEditor<EntityModelNostroStagingUpdateDto>(
          "addrL2",
          "stagedAddrL2"
        ),
      },
      {
        titleFormatter: actionFormatter,
        field: "addressL2Update",
        formatter: customChecboxFormatter,
        headerSort: false,
        width: 50,
      },

      // Address L4
      {
        title: "Address L4 &<br/> Staged Address L4",
        field: "stagedAddrL4",
        formatter: createDoubleLineFormatter<EntityModelNostroStagingUpdateDto>(
          "addrL4",
          "stagedAddrL4"
        ),
        editor: createDoubleLineEditor<EntityModelNostroStagingUpdateDto>(
          "addrL4",
          "stagedAddrL4"
        ),
      },
      {
        titleFormatter: actionFormatter,
        field: "addressL4Update",
        formatter: customChecboxFormatter,
        headerSort: false,
        width: 50,
      },

      // Rel Manager

      /*{ title: "Rel Manager", field: "rmNumber", maxWidth: 90 },
      {
        title: "Staged RM",
        field: "stagedRmNumber",
        editor: "list",
        maxWidth: 90,
        editorParams: {
          values:
            rms &&
            rms.map((elem: EntityModelRelationshipManagerDto) => elem.number),
        },
      },*/
      {
        title: "RM &<br/>Staged RM",
        field: "stagedRmId",
        formatter: createDoubleLineFormatter<
          EntityModelNostroStagingUpdateDto,
          EntityModelRelationshipManagerDto
        >("rmNumber", "stagedRmId", {
          entries: rms!,
          matchDisplayEntryRowField: "rmId",
          matchEditEntryRowField: "stagedRmId",
          entryKeyField: "id",
          entryValueField: "number",
        }),
        editor: createDoubleLineSelectEditor<
          EntityModelNostroStagingUpdateDto,
          EntityModelRelationshipManagerDto
        >("rmNumber", "stagedRmId", {
          optionDisplayField: "number",
          optionValueField: "id",
          options: rms!,
        }),
        maxWidth: 130,
      },
      {
        titleFormatter: actionFormatter,
        field: "relationshipManagerUpdate",
        formatter: customChecboxFormatter,
        headerSort: false,
        width: 50,
      },

      // Status

      /*{
        title: "Status",
        field: "statusId",
        maxWidth: 110,
        formatter: (cell: any) => {
          let status =
            statuses &&
            statuses.find(
              (elem: EntityModelStatusDto) => elem.statusId === cell.getValue()
            )?.statusText;
          console.log("status", status);
          return status;
        },
      },
      {
        title: "New Status",
        field: "newStatus",
        editor: "list",
        maxWidth: 110,
        editorParams: {
          values:
            statuses &&
            statuses.map((elem: EntityModelStatusDto) => elem.statusText),
        },
      },*/
      {
        title: "Status &<br/>New Status",
        field: "newStatusId",
        formatter: createDoubleLineFormatter<
          EntityModelNostroStagingUpdateDtoExt,
          EntityModelStatusDto
        >("statusId", "newStatusId", {
          entries: statuses,
          matchDisplayEntryRowField: "statusId",
          matchEditEntryRowField: "newStatusId",
          entryKeyField: "statusId",
          entryValueField: "statusText",
        }),
        editor: createDoubleLineSelectEditor<
          EntityModelNostroStagingUpdateDtoExt,
          EntityModelStatusDto
        >("statusId", "newStatusId", {
          optionDisplayField: "statusText",
          optionValueField: "statusId",
          options: statuses,
          useOptionsToDisplayValues: true,
        }),
        maxWidth: 130,
      },
    ];
    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };
  }, [dataTableApiCall, rms, statuses]);

  function filteredSearch(event: CustomEvent) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  function rowSelectionChanged(rowSelectionChanged: CustomEvent) {
    console.log("rowSelectionChanged", rowSelectionChanged.detail);

    setSelected(rowSelectionChanged.detail.data);
  }

  async function cellEditedHandler(cell: CellComponent, table: any) {
    const data = cell.getData() as NostroUpdateDto;
    const value = cell.getValue();
    console.log("cell data", data, value);

    if (rms && cell.getColumn().getField() === "stagedRmId") {
      await cell.getRow().update({
        //stagedRmId: value,
        stagedRmNumber: rms.filter((rm) => rm.id === value)[0].number,
      });
    }

    if (statuses && cell.getColumn().getField() === "newStatusId") {
      await cell.getRow().update({
        newStatusId: statuses.filter((s) => s.statusId === value)[0].statusId,
      });
    }

    cell.getRow().select();
  }

  function updateSelectedNostro() {
    setIsFetching(true);
    authorizedCall(NostroControllerService.updateSelectedNostro, {
      requestBody: {
        nostroUpdateDtoList: selected,
      },
    })
      .then(() => {
        dataTable.current!.options = { ...dataTable.current!.options };
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  console.log("RENDER - NostroToUpdate [isFetching:", isFetching, "]");

  return (
    <>
      <div className="mb-4 mt-4">
        <ZfBanner type="info">
          There are changed Nostro after import procedure, please update their
          statuses
        </ZfBanner>
      </div>
      <div className="content p-4 bg-white">
        <div
          className="flex flex-row pb-6"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <div className="basis-1/2">
            <p
              className="heading--two text-xl"
              data-testid="view-acc-cat-pagetitle"
            >
              Nostro to update
            </p>
          </div>
          <div className="basis-1/4">
            <ZfSearchField
              placeholder="Search ..."
              onInputChange={filteredSearch}
              ref={searchFilter}
            ></ZfSearchField>
          </div>
          <div className="basis-1/4"></div>
          <div className="basis-1/4 flex flex-row-reverse">
            <ZfTextButton
              hierarchy="primary"
              disabled={!selected.length || isFetching}
              onClick={(_) => updateSelectedNostro()}
              className="mr-2"
              size="small"
            >
              Update Selected
            </ZfTextButton>
            <ZfIconButton
              hierarchy="secondary"
              icon="arrow_back"
              className="mr-1"
              onClick={() => navigate(`/`)}
              size="small"
            ></ZfIconButton>
          </div>
        </div>
        {isFetching && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}
        {error && <p className="error">Error in loading data</p>}

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) =>
            tableBuiltHandler(event, setIsFetching, cellEditedHandler)
          }
          onTableRowSelectionChanged={rowSelectionChanged}
          //hidden={type === "WITH_BALANCE" && datepicker === ""}
          className="mt-5"
          style={{
            pointerEvents: isFetching && "none",
            opacity: isFetching && 0.7,
          }}
          data-testid="nos-to-update-table"
        ></ZfTable>
      </div>
    </>
  );
};

export default NostroToUpdate;
